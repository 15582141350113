<!-- 作业详情 未做 只展示标题--->
<style lang="scss" scoped>
@import '@/assets/styles/questionsItem.scss';
@import '@/assets/styles/questions.scss';
.page{
	height: 100%;
    position: relative;
	width: 100%;
    height: 100%;
    box-sizing: border-box;
	&.showbtn{
    	padding-bottom: 80px;
	}
}
*{
	box-sizing: border-box;
}

.content{
	padding: 32px 0px 0px;
    overflow: auto;
    height: calc(100% - 60px);
    box-sizing: border-box;
	background-color: #E8EAF3;
	&.showall{
   	 height: auto;
	 width: 1100px;
	}
}
.testPaper{
	padding: 20px;
	width: 100%;
	background: #FDFDFD;
	box-shadow: 0px 14px 40px -17px #B7D0DE;
	border-radius: 14px;
	position: relative;
	.excellent{
		position: absolute;
		right: 20px;
		bottom: 20px;
		width: 80px;
		height: 80px;
	}
	.name{
		height: 33px;
		font-size: 18px;
		font-weight: 400;
		color: #444444;
		line-height: 33px;
	}
	.teacherInfo{
		font-size: 16px;
		font-weight: 400;
		color: #888888;
		line-height: 28px;
		// &:hover{
		// 	cursor: pointer;
		// 	color: #FF4D4F;
		// }
		.avatar{
			width: 26px;
			height: 26px;
			border-radius: 50%;
			margin-right: 7px;
		}
	}
	.sthom_title{
		font-size: 16px;
		font-weight: 400;
		color: #333333;
		line-height: 36px;
	}
	.endDate{
		height: 28px;
		font-size: 14px;
		font-weight: 400;
		color: #01A1FD;
		line-height: 28px;
		background:#E3F3FD;
		border-radius: 100px;
		display: block;
		width: 250px;
		text-align: center;
	}
	.examine{
		width: 70px;
		height: 28px;
		border-radius: 100px;
		line-height: 28px;
		font-size: 14px;
		margin-left: 10px;
		text-align: center;
		&.type1{
			background: rgba(255,124,18,0.1);
			color: $comOrange;
		}
		&.type2{
			background: rgba(170,170,170,0.1);
			color: $comAAA;
		}
		&.type3{
			background: #FDEBEB;
			color: $comRed;
		}
	}
	.btn{
		width: 128px;
		height: 64px;
		border-radius: 14px;
		color: #FFFFFF;
		font-size:28px;
		line-height: 64px;
		text-align: center;
		&.type1{
			background: $comBlue;
		}
		&.type2{
			background: $comGray;
			color: #888888;
		}
	}
	.title{
		margin-top: 12px;
		font-size: 16px;
		font-weight: 400;
		color: #444444;
		line-height: 24px;
	}
	.remark{
		margin-top: 24px;
		width: 100%;
		background-color: #FFFFFF;
		border-radius: 14px;
		position: relative;
		display: inline-block;
		.iconimg{
			position: absolute;
			left: 0px;
			top: 0px;
			width: 48px;
			height: 45px;
		}
		.teacherRemark{
			width: 160px;
			height: 52px;
			background: rgba(255,124,18,0.102);
			border-radius: 0px 30px 30px 0px;
			line-height: 52px;
			text-align: center;
			font-size: 28px;
			color: $comOrange;
			font-weight: bold;
			margin-top: 13px
		}
		.value{
			font-size: 28px;
			line-height: 36px;
			color: $comOrange;
			margin-top: 13px;
		}
	}
}
.print{
	padding-top: 20px;
	i{
		font-size: 16px;
		margin-right: 6px;
	}
}

// 上传试题弹窗
.feeback-form {
    width: 100%;
    box-sizing: border-box;
    padding: 32px 50px;
    height: calc(100% - 64px);
    background-color: #ffffff;
    border-radius: 30px;
    margin-top: 32px;
    .img-uploader {
      min-width: 102px;
      min-height: 102px;

      ::v-deep .el-upload--text {
        min-height: 102px;
		height: auto !important;
      }
	  
      .img-list {
        width: 366px;
        display: flex;
        flex-wrap: wrap;
      }
      .img-box {
        min-height: 102px;
        margin-right: 20px;
        margin-bottom: 20px;
        position: relative;

        .el-image {
          border-radius: 10px;
        }

        .del {
          width: 24px;
          height: 24px;
          background: #fff;
          position: absolute;
          top: -8px;
          right: -8px;
          font-size: 32px;
          border-radius: 50%;
          color: #f8516d;
          @include flexBox(center);
        }
      }

      .upload-wrapper {
        width: 102px;
        height: 102px;
        background: #2ac293;
        border-radius: 10px;
        color: #fff;
        font-size: 50px;
        @include flexBox(center);
      }
    }
  }
  .questions .topic{
	margin-top: 0 !important;
	margin-bottom: 0 !important;
  }
  .questions .li .li,.questions .group .li .li{
	margin-top: 10px;
	margin-bottom: 0px;
	background: #f6fcf9;
  }
  .questions .group .li{
	background-color: #FFFFFF;
	margin-top: 0px;
  }
  .selectimg{
	max-width: 100%;
	margin: 0px 0 0 20px;
	display: block;
  }
  .flex_1+.selectimg,.val+.selectimg{
	margin-top: 20px;
  }
</style>

<template>
    <div class="page" :class="showAchieve(homework_info.sthom_wor_deadlinetime)?'showbtn':''">
		<div class="print flex_end_align">
			<el-button size="small" type="primary" @click="handlePrint(printData)"><i class="el-icon-printer"></i>打印作业</el-button>
		</div>
		<div class="content" id="printFrom" :class="showall?'showall':''">
			<div class="testPaper">
				<div class="flex_bet_align" v-if="homework_info.sysub_name">
					<div class="flex_1">
						<div class="flex_bet_align">
							<div class="name">{{homework_info.sysub_name}}作业</div>
							<div class="teacherInfo flex_align"> <!-- @click="goChat(homework_info)" -->
								<img class="avatar" v-if="homework_info.teuse_image" :src="homework_info.teuse_image" alt="">
								{{homework_info.teuse_name}}
							</div>
						</div>
						<div class="sthom_title">
							{{homework_info.sthom_wor_title}}
						</div>
						<div class="flex_align mt-16" v-if="type==1">
							<div class="endDate">截止时间：{{homework_info.sthom_wor_deadlinetime}} 前</div>
							<div class="examine type1" v-if="homework_info.sthom_wor_status==30">已批阅</div>
							<div class="examine type2" v-if="homework_info.sthom_wor_status==10">待批阅</div>
							<div class="examine type3" v-if="homework_info.sthom_wor_status==20">待完成</div>
						</div>
					</div>
				</div>
				<div class="title">{{homework_info.sthom_wor_remark}}</div>
			</div>
			<div class="questions">
				<div v-for="(item,index) in homework_list" :key="index">
					<div class="type flex_align" v-if="index==0">{{item.syque_typ_name}}</div>
					<div class="type flex_align" v-else-if="homework_list[index-1].syque_typ_name!=item.syque_typ_name">{{item.syque_typ_name}}</div>
					<div class="li">
						<div class="topic">
							<div class="flex">
								<div class="number">{{index+1}}、</div>
								<div>
									<div class="val" v-if="item.sthom_que_title">
										<div class="richprint" v-html="item.sthom_que_title"></div>
									</div>
									<img class="selectimg" v-if="item.sthom_que_image" :src="item.sthom_que_image" alt="">
								</div>
							</div>
							<div v-if="item.syque_typ_id==1||item.syque_typ_id==2||item.syque_typ_id==5">
								<div v-for="(item2,index2) in item.question_item" :key="index2">
									<div class="li flex">
										<div class="iconfont icona-dui">&#xe6db;</div>
										<div class="iconfont icona-cuo">&#xe6dc;</div>
										<div class="number">{{item2.sthom_que_ite_code}}.</div>
										<div class="flex_1">
											<div class="flex_1" v-if="item2.sthom_que_ite_title">
												<div class="richprint" v-html="item2.sthom_que_ite_title"></div>
											</div>
											<div class="flex_1" v-else></div>
											<img class="selectimg" v-if="item2.sthom_que_ite_image" :src="item2.sthom_que_ite_image" alt="">
											<!-- <el-image v-if="item2.sthom_que_ite_image" style="width: 100px; height: 100px" :src="item2.sthom_que_ite_image" :preview-src-list="[item2.sthom_que_ite_image]"></el-image> -->
										</div>
									</div>
								</div>
							</div>
							<!-- <el-image v-if="item.sthom_que_image" style="width: 100px; height: 100px" :src="item.sthom_que_image" :preview-src-list="[item.sthom_que_image]"></el-image> -->
						</div>
						<!-- 组合题 -->
						<div v-if="item.syque_typ_id==14" class="group">
							<div v-for="(item2,index2) in item.childrenQuestion" :key="index2">
								<div class="li">
									<div class="topic flex">
										<div class="number">{{index2+1}}、</div>
										<div class="flex_1">
											<div class="val" v-if="item2.sthom_que_title">
												<div class="richprint" v-html="item2.sthom_que_title"></div>
											</div>
											<!-- <el-image v-if="item2.sthom_que_image" style="width: 100px; height: 100px" :src="item2.sthom_que_image" :preview-src-list="[item2.sthom_que_image]"></el-image> -->
											<img class="selectimg" v-if="item2.sthom_que_image" :src="item2.sthom_que_image" alt="">
										</div>
									</div>
									<div v-if="item2.syque_typ_id==1||item2.syque_typ_id==2||item2.syque_typ_id==5">
										<div v-for="(item3,index2) in item2.question_item" :key="index2">
											<div class="li flex">
												<div class="number">{{item3.sthom_que_ite_code}}.</div>
												<div class="flex_1">
													<div class="flex_1" v-if="item3.sthom_que_ite_title">
														<div class="richprint" v-html="item3.sthom_que_ite_title"></div>
													</div>
													<div class="flex_1" v-else></div>
													<img class="selectimg" v-if="item3.sthom_que_ite_image" :src="item3.sthom_que_ite_image" alt="">
													<!-- <el-image v-if="item3.sthom_que_ite_image" style="width: 100px; height: 100px" :src="item3.sthom_que_ite_image" :preview-src-list="[item3.sthom_que_ite_image]"></el-image> -->
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-if="showall"><br></div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="flotageBtn" v-if="showAchieve(homework_info.sthom_wor_deadlinetime)">
			<div class="combtn3" @click="shouPhotograph=true">拍照答题</div>
			<div class="combtn5" @click="goAccomp" v-if="homework_list.length>0">立即答题</div>
		</div>
		<!-- 显示答题进度 -->
		<el-dialog title="上传作业" :visible.sync="shouPhotograph" width="50%" :close-on-press-escape="false" :close-on-click-modal="false">
			<div class="feeback-form">
				<el-form ref="formData" :model="formData" label-width="68px" :rules="rules" >
					<el-form-item label="上传图片" prop="plfee_image">
					<el-upload ref="imgUploader" class="img-uploader" :action="$upload.imgAction" :headers="$upload.header" :show-file-list="formData.plfee_image.length==0" :file-list="formData.plfee_image"  :accept="$upload.imgAccept" :before-upload="$beforImgUpload" :on-success="(res) => {return uploadImgSuccess(res); }"
						:on-exceed=" () => { $message.warning('上传文件数量超出限制，最多上传4个'); }"
						multiple
						:limit="4"
					>
						<div class="img-list">
						<template v-if="formData.plfee_image.length">
							<div
							class="img-box"
							v-for="(item, index) in formData.plfee_image"
							:key="index"
							>
							<el-image
								style="width: 102px; height: 102px"
								:src="formatfile(item)"
								:preview-src-list="[formatfile(item)]"
							/>
							<i class="del iconfont" @click.stop="delImg(index)"
								>&#xe63e;</i
							>
							</div>
						</template>
						<div
							class="upload-wrapper"
							v-if="formData.plfee_image.length < 9"
						>
							<i class="iconfont">&#xe63d;</i>
						</div>
						</div>
					</el-upload>
					</el-form-item>
					<el-form-item>
					<el-button
						type="custom_primary"
						size="small"
						@click="handleSubmit"
						v-loading="btnload"
						>确 定</el-button
					>
					</el-form-item>
				</el-form>
			</div>
        </el-dialog>
	</div>
</template>

<script>
import print from "/lib/print-js";// 打印插件
import { homework_info,photo_answers } from "@api/home"
import { formatFile } from "@utils";
export default {
	computed: {
		formatfile() {
			return function (url) {
				return formatFile(url);
			};
		},
	},
    name: 'schoolPlay',
    data() {
		var validateimage = (rule, value, callback) => {
			if (this.formData.plfee_image.length<1) {
				return callback(new Error('请上传答题'));
			}else{
				callback();
			}
		};
        return {
			type:2,
			//作业详情
			homework_info:{},
			homework_list:{},
            sthom_wor_id:0,
			showall:false,
			//打印参数设置
			printData: {
				printable: "printFrom",
				header: "",
				ignore: ["no-print"],
			},
			//上传试题弹窗
			shouPhotograph:false,
			// 试题窗口
			formData: {
				plfee_image: [],
			},
			btnload: false,
			rules: {
				plfee_image: [
				{ validator: validateimage, trigger: 'change' },
				],
			},
        }
    },
    created() {
        this.sthom_wor_id=this.$route.params.id
        this.type=this.$route.params.type
        this.getData();
    },
    methods: {
        /** 获取公开课列表数据 */
        async getData() {
            let { data } = await homework_info({sthom_wor_id:this.sthom_wor_id});
			this.homework_info=data
			this.homework_list=data.question
            this.$forceUpdate();
        },
		goDetails(){
		},
		//判断去完成字段是否显示
		showAchieve(date){
			if(date){
				try{
					return new Date(date.replace(/-/g, '/')) > new Date()
				} catch (e) {
					return false
				}
			}else{
				return false
			}
		},
		//打印函数
		handlePrint(params) {
			this.showall=true
			setTimeout(() => {
				print({
					printable: params.printable, // 'printFrom', // 标签元素id
					type: params.type || "html",
					header: params.header, // '表单',
					targetStyles: ["*"],
					style: "@page {margin:0mm};", // 可选-打印时去掉眉页眉尾
					ignoreElements: params.ignore || [], // ['no-print']
					properties: params.properties || null,
					maxWidth: 1300,
				});
			}, 200);
			setTimeout(() => {
				this.showall=false
			}, 500);
		},
		
		
		/**
		 * 图片上传成功
		 */
		 uploadImgSuccess({ data, el }) {
			if (this.$isEmpty(data)) return this.$message.error(msg);
			this.formData.plfee_image.push(data.address);
			this.$forceUpdate();
		},
		/** 删除图组图片 */
		delImg(index) {
			let imgs = [...this.formData.plfee_image];
			this.$refs.imgUploader.uploadFiles.splice(index, 1);
			imgs.splice(index, 1);
			this.formData = {
				...this.formData,
				plfee_image: [...imgs],
			};
		},

		/** 提交试题 */
		handleSubmit() {
			this.$refs.formData.validate(async (valid) => {
				if (valid) {
					this.btnload = true;
					let params = {
						sthom_wor_id: this.sthom_wor_id,
						answerimg: this.formData.plfee_image.join(","),
					};
					let res = await photo_answers(params);
					this.btnload = false;
					if (res) {
						this.shouPhotograph=false;
						this.$message.success("提交成功");
						this.goexamIndex();
					}
				}
			});
		},
		// 返回首页
		goexamIndex(){
            this.$router.push({
				name:"HOME",
				params: {}
			})
		},
        // 跳转聊天
        goChat(item){
            let userProfile={
                userID: item.tencent_im_account,
                avatar: item.teuse_image,
                nick: item.teuse_name,
            }
            this.$router.push({name:'INTERACTION_MESSAGE',params: { userProfile:userProfile}})
        },
		goAccomp(){
			this.$router.push({
				name:"JOB_ACCOMP_LISH",
				params: {
					id:this.sthom_wor_id,
				}
			})
		}
    },
}
</script>